<template>
  <div class="governance promo" id="promo">
    <div class="ui grid container-1">
      
      <img width="2200px" src="@/assets/promobanner.png" alt="Tayocash" />
      
    </div>
    
    <div class="ui grid container">
      <div class="intro-content promo">
        <h3>Use your TayoCash app. Earn Tickets. Win up to Php 10,000 Weekly!</h3>
        <div class="paragraph">
          <p>The Promo Mechanics of the TAYOCASH RAFFLE SPLASH promo, which shall constitute the Terms and Conditions of the promo, are as follows:</p>

          
          <ol>
            <h4><b>A. Registration, Weekly Draws and Prizes</b></h4>

            <li><span>
              The promo shall run from {{ formatDateDisplayWithoutTime(fromDrawDate) }} to {{ formatDateDisplayWithoutTime(toDrawDate)  }}.
              </span>
            </li>

            <li><span>
              The promo is open to new and existing TayoCash subscribers who are 18 years old and above at the time they join the promo.
              </span>
            </li>

            <li><span>
              To join the promo, TayoCash subscribers are required to register via the promo tab found on the homepage of their TayoCash app, agree to these Promo Mechanics and provide the following required information: Full Name, Mobile Number, Email Address, and Home Address.
              </span>
            </li>

            <li><span>
              By joining and participating in the raffle promo participants confirm, agree to, and acknowledge that they have read the full mechanics of the promotion and agree to abide by its terms and conditions.
            </span>
            </li>

            <li><span>
              Once registered, participants can earn tickets to participate in all 28 electronic weekly draws to be held every Thursday following the end of each Promo Week (12:00 AM Monday to 11:59 PM Sunday) based on the following app activities:
              </span>
            </li>

            <div class="table-body">
              <table class="ui celled structured table unstackable promo">
                <thead>
                  <tr>
                    <th>Covered TayoCash Activities</th>
                    <th>Tickets</th>
                    <th>Conditions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Existing and New Fully-Verified TayoCash Account Registration</td>
                    <td>1 Ticket / account</td>
                    <td>1. Upon registration to the promo, existing and new Fully-Verified TayoCash accounts shall receive 1 Ticket. <br /><br />2. This ticket can only be earned once.</td>
                  </tr>
                  <tr>
                    <td>
                      TayoCash Account Upgrade from Basic or Semi-Verified to Fully-Verified Account
                    </td>
                    <td>1 Ticket / account</td>
                    <td>1. The Ticket shall be applied to the applicable promo week when his or her application for a Fully-Verified account is approved. <br /><br />2. This ticket can only be earned once.</td>
                  </tr>
                  <tr>
                    <td>Account Upgrade from Basic or Semi-Verified to Fully-Verified Account</td>
                    <td>1 Ticket / account</td>
                    <td>Users shall only be eligible to earn this type of ticket once.</td>
                  </tr>
                  <tr>
                    <td>Buy Load</td>
                    <td>1 Ticket for every P100 of a single-receipt Buy Load transaction</td>
                    <td>Example: 3 Tickets for a single Buy Load receipt of P350.00.</td>
                  </tr>
                  <tr>
                    <td>Pay Bills</td>
                    <td>1 Ticket for every P100 of a single-receipt Pay Bills transaction</td>
                    <td>Example: 3 Tickets for a single Pay Bills receipt of P350.00.</td>
                  </tr>
                  <tr>
                    <td>PayQRPH</td>
                    <td>2 Tickets for every P100 of a single-receipt PayQRPH transaction</td>
                    <td>Example: 6 Tickets for a single PayQRPH receipt of P350.00.</td>
                  </tr>
                  <tr>
                    <td>Refer-a-Friend</td>
                    <td>2 Tickets to the Recruiter and 1 Ticket to the Recruit</td>
                    <td>1. Recruiter must be a Fully-Verified TayoCash account. <br /><br />2. Recruit must register for a Fully-Verified TayoCash account. <br /><br />3. Recruit must use the referral code of the Recruiter. <br /><br />4. Tickets shall be credited upon approval of the Fully-Verified TayoCash account of the Recruit. <br /><br />5. Recruiter is not allowed to refer themselves for another account that will also be their own. <br /><br />6. Recruit shall only be eligible to earn this type of Ticket once.</td>
                  </tr>

                </tbody>
              </table>
            </div>
            <br />
            <li><span>
              Each Promo Week will have their own draw date as shown in the table below:
              </span>
              <ul>
                <li>
                  Each Promo Week will have their own draw date as shown in the table
                </li>
                <li>
                  A TayoCash subscriber can only win once during each draw. If the name of the TayoCash subscriber is drawn more than once, another entry shall be drawn and qualified.
                </li>
                <li>
                  A winning ticket should not be a subject of or involved in any pending transaction dispute or complaint at the time of the draw date. If afterwards, the transaction becomes a subject of a dispute or reversal, TayoCash reserves the right to refuse to award, or recall/withdraw even if already credited, tickets and prize winnings.
                </li>
                <li>
                  After each draw date, all winning and non-winning tickets shall be tagged as expired and excluded from the subsequent draws.
                </li>
              </ul>
            </li>

            <div class="table-body">
              <table class="ui celled structured table unstackable promosecond">
                <thead>
                  <tr>
                    <th>Promo Week</th>
                    <th>Draw Date</th>
                    <th>Prize (each)</th>
                    <th>No. of Winners</th>
                    <th>Total Prizes</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in adminGetPromoSchedule" :key="item.id">
                    <td>{{ formatDateDisplayWithoutTime(item.dateFrom) }} - {{ formatDateDisplayWithoutTime(item.dateTo) }}</td>
                    <td>{{ formatDateDisplayWithoutTime(item.drawDate) }}</td>
                    <td>₱{{ item.amount }}</td>
                    <td>{{ item.numberOfWinner }}</td>
                    <td>₱{{ item.amount * item.numberOfWinner }}</td>
                  </tr>
                </tbody>
                <!--
                <tbody>
                  <tr>
                    <td>December 12-18, 2022</td>
                    <td>December 22, 2022</td>
                    <td>₱5,000.00</td>
                    <td>5</td>
                    <td>₱25,000.00</td>
                  </tr>
                  <tr>
                    <td>December 19-25, 2022</td>
                    <td>December 29, 2022</td>
                    <td>₱5,000.00</td>
                    <td>5</td>
                    <td>₱25,000.00</td>
                  </tr>
                  <tr>
                    <td>December 26 - January 01, 2022</td>
                    <td>January 05, 2023</td>
                    <td>₱5,000.00</td>
                    <td>5</td>
                    <td>₱25,000.00</td>
                  </tr>
                  <tr>
                    <td>January 02 - 08, 2023</td>
                    <td>January 12, 2023</td>
                    <td>₱5,000.00</td>
                    <td>5</td>
                    <td>₱25,000.00</td>
                  </tr>
                  <tr>
                    <td>January 09 - 15, 2023</td>
                    <td>January 19, 2023</td>
                    <td>₱5,000.00</td>
                    <td>5</td>
                    <td>₱25,000.00</td>
                  </tr>
                  <tr>
                    <td>January 16 - 22, 2023</td>
                    <td>January 26, 2023</td>
                    <td>₱5,000.00</td>
                    <td>5</td>
                    <td>₱25,000.00</td>
                  </tr>
                  <tr>
                    <td>January 23 - 29, 2023</td>
                    <td>February 02, 2023</td>
                    <td>₱10,000.00</td>
                    <td>10</td>
                    <td>₱100,000.00</td>
                  </tr>
                  <tr>
                    <td>January 30 - February 05, 2023</td>
                    <td>February 09, 2023</td>
                    <td>₱5,000.00</td>
                    <td>5</td>
                    <td>₱25,000.00</td>
                  </tr>
                  <tr>
                    <td>February 6 - 12, 2023</td>
                    <td>February 16, 2023</td>
                    <td>₱5,000.00</td>
                    <td>5</td>
                    <td>₱25,000.00</td>
                  </tr>
                  <tr>
                    <td>February 13 - 19, 2023</td>
                    <td>February 23, 2023</td>
                    <td>₱5,000.00</td>
                    <td>5</td>
                    <td>₱25,000.00</td>
                  </tr>



                  <tr>
                    <td>February 20 - 26, 2023</td>
                    <td>March 02, 2023</td>
                    <td>₱5,000.00</td>
                    <td>5</td>
                    <td>₱25,000.00</td>
                  </tr>
                  <tr>
                    <td>February 27 - March 5, 2023</td>
                    <td>March 09, 2023</td>
                    <td>₱5,000.00</td>
                    <td>5</td>
                    <td>₱25,000.00</td>
                  </tr>
                  <tr>
                    <td>March 6 - 12, 2023</td>
                    <td>March 16, 2023</td>
                    <td>₱5,000.00</td>
                    <td>5</td>
                    <td>₱25,000.00</td>
                  </tr>
                  <tr>
                    <td>March 13 - 19, 2023</td>
                    <td>March 23, 2023</td>
                    <td>₱10,000.00</td>
                    <td>10</td>
                    <td>₱100,000.00</td>
                  </tr>
                  <tr>
                    <td>March 20 - 26, 2023</td>
                    <td>March 30, 2023</td>
                    <td>₱5,000.00</td>
                    <td>5</td>
                    <td>₱25,000.00</td>
                  </tr>
                  <tr>
                    <td>March 27 - April 02, 2023</td>
                    <td>April 06, 2023</td>
                    <td>₱5,000.00</td>
                    <td>5</td>
                    <td>₱25,000.00</td>
                  </tr>
                  <tr>
                    <td>April 03 - 09, 2023</td>
                    <td>April 13, 2023</td>
                    <td>₱5,000.00</td>
                    <td>5</td>
                    <td>₱25,000.00</td>
                  </tr>
                  <tr>
                    <td>April 10 - 16, 2023</td>
                    <td>April 20, 2023</td>
                    <td>₱10,000.00</td>
                    <td>10</td>
                    <td>₱100,000.00</td>
                  </tr>
                  <tr>
                    <td>April 17 - 23, 2023</td>
                    <td>April 27, 2023</td>
                    <td>₱5,000.00</td>
                    <td>5</td>
                    <td>₱25,000.00</td>
                  </tr>
                  <tr>
                    <td>April 24 - 30, 2023</td>
                    <td>May 04, 2023</td>
                    <td>₱5,000.00</td>
                    <td>5</td>
                    <td>₱25,000.00</td>
                  </tr>
                  <tr>
                    <td>May 01 - 07, 2023</td>
                    <td>May 11, 2023</td>
                    <td>₱5,000.00</td>
                    <td>5</td>
                    <td>₱25,000.00</td>
                  </tr>
                  <tr>
                    <td>May 01 - 07, 2023</td>
                    <td>May 11, 2023</td>
                    <td>₱5,000.00</td>
                    <td>5</td>
                    <td>₱25,000.00</td>
                  </tr>
                  <tr>
                    <td>May 08 - 14, 2023</td>
                    <td>May 18, 2023</td>
                    <td>₱10,000.00</td>
                    <td>10</td>
                    <td>₱100,000.00</td>
                  </tr>
                  <tr>
                    <td>May 15 - 21, 2023</td>
                    <td>May 25, 2023</td>
                    <td>₱5,000.00</td>
                    <td>5</td>
                    <td>₱25,000.00</td>
                  </tr>
                  <tr>
                    <td>May 22 - 28, 2023</td>
                    <td>June 01, 2023</td>
                    <td>₱5,000.00</td>
                    <td>5</td>
                    <td>₱25,000.00</td>
                  </tr>
                  <tr>
                    <td>May 22 - 28, 2023</td>
                    <td>June 01, 2023</td>
                    <td>₱5,000.00</td>
                    <td>5</td>
                    <td>₱25,000.00</td>
                  </tr>
                  <tr>
                    <td>May 29 - June 04, 2023</td>
                    <td>June 08, 2023</td>
                    <td>₱5,000.00</td>
                    <td>5</td>
                    <td>₱25,000.00</td>
                  </tr>
                  <tr>
                    <td>June 04 - 11, 2023</td>
                    <td>June 15, 2023</td>
                    <td>₱10,000.00</td>
                    <td>10</td>
                    <td>₱100,000.00</td>
                  </tr>
                  <tr>
                    <td>June 12 - 18, 2023</td>
                    <td>June 22, 2023</td>
                    <td>₱10,000.00</td>
                    <td>10</td>
                    <td>₱100,000.00</td>
                  </tr>

                  <tr>
                    <td>June 19 - 25, 2023</td>
                    <td>June 29, 2023</td>
                    <td>₱10,000.00</td>
                    <td>10</td>
                    <td>₱100,000.00</td>
                  </tr>
                </tbody>
                -->
              </table>
            </div>
            <br /><br />
            <li><span>
              If a draw date falls on, or is declared, a non-working holiday, the draw shall be conducted on the next business day, without further notice.
              </span>
            </li>

            <li><span>
              Winning tickets shall be electronically drawn by TayoCash using a DTI-registered randomizer at Unit 2603, Antel Global Corporate Center, Ortigas Center, Pasig City at 10:00 AM in the presence of DTI representatives, who shall attend via Zoom, the URL of which shall be provided by TayoCash. The proceedings of the raffle draw shall be recorded. Prize winnings shall be credited to the TayoCash account of the winner and considered redeemed upon crediting of the same.
              </span>
            </li>

            <li><span>
              The 20% prize tax shall be for the account of the winners and withheld by TayoCash. Any incidental expenses shall likewise be for the account of the winner.
              </span>
            </li>
            <li><span>
              All winnings are non-transferable and non-convertible to cash.
            </span>
            </li>
            <h4 class="ha"><b>
              B. Notification, Verification, Redemption and Publication
              </b>
            </h4>
            <li><span>
              Winners will be notified by TayoCash via registered mail and email within 3 days from draw date.
            </span>
            </li>
            <li><span>
              To redeem their prizes, winners must comply with the following requirements within 60 calendar days from receipt of the registered mail above:
            </span>
              <ul>
                <li>
                  Submission of Tax Identification Number, and
                </li>
                <li>
                  Upgrade to Fully-Verified TayoCash account, in case of Basic or Semi-Verified TayoCash account winners.
                </li>
              </ul>
            </li>
            <li><span>
              Prize winnings shall be credited to the TayoCash account of the winner within seven (7) business days from full compliance with the redemption requirements above. Winners will be notified via email of the wallet crediting.
              </span>
            </li>
            <li><span>
              Winners must acknowledge receiving the prize winning in his or her TayoCash account within seven (7) business days from receipt of the notice of wallet crediting. Failure to acknowledge within the given period means the wallet crediting was successful.
              </span>
            </li>
            <li><span>
              Crediting of prizes shall observe the applicable monthly aggregate wallet limit of the TayoCash account of the winners. If it shall breach the account wallet limit, the prize winnings will be credited as soon as the wallet limit has been refreshed.
              </span>
            </li>
            <li><span>
              Failure to comply with the redemption requirements within the given period shall cause the forfeiture of the prize in favor of TayoCash with prior approval of DTI and notice to the participant.
            </span>
            </li>
            <li><span>
              TayoCash reserves the right to verify the information submitted by each winner and refuse to award the prize, if such verification is not accomplished to the satisfaction of TayoCash.
            </span>
            </li>
            <li><span>
              TayoCash reserves the right to disclose and publish the names and city of residence of the winners in any mode or manner deemed appropriate.
            </span>
            </li>
            <h4 class="ha"><b>C. Other Terms and Conditions</b></h4>
            <li><span>
              Officers, employees, promo contractors and service providers of TayoCash, their agencies, subsidiaries, their relatives up to the second degree of consanguinity and affinity, as well as former employees of TayoCash separated within one (1) year from the start of the promo are disqualified from joining the raffle promo.
            </span>
            </li>
            <li><span>
              Inactive, suspended, or deactivated TayoCash accounts are not eligible to join the promo, unless such accounts are reactivated during the promo period.
            </span>
            </li>
            <li><span>
              Participants whose TayoCash accounts will be suspended or deactivated during the promo period shall lose or forfeit tickets earned prior to such suspension or deactivation, with no right to recollect such tickets notwithstanding a subsequent account reactivation or lifting of the suspension.
            </span>
            </li>
            <li><span>
              TayoCash reserves the right to cancel, suspend and/or modify the promo, or any part of it, if any fraud or other factors beyond TayoCash’s reasonable control impair the integrity or proper functioning thereof, as determined by TayoCash with prior approval of DTI.
            </span>
            </li>
            <li><span>
              TayoCash reserves the right to disqualify TayoCash subscribers, or to refuse or withdraw/recall tickets and prizes even if the same have been credited already, that are results of fraudulent activities, or abuse of TayoCash app and promo, or any violation of these Promo Mechanics, which constitutes the terms and conditions of the promo.
            </span>
            </li>
            <li><span>
              TayoCash reserves the right to amend the Promo Mechanics at any time with prior approval of DTI.
            </span>
            </li>
            <li><span>
              TayoCash will not be liable for any loss or claim, whether direct or indirect, which arises from this promo.
            </span>
            </li>
            <li><span>
              The TayoCash Terms and Conditions for the usage of the TayoCash app and its services shall apply.
            </span>
            </li>
            <li><span>
              All disputes pertaining to the eligibility of the participants for the promo or claims arising out of or connected with the promo shall be resolved by TayoCash. The decision of TayoCash with concurrence of DTI in such dispute or claim shall be final.
            </span>
            </li>
            <li><span>
              By receipt of any prize, the winner agrees to release and hold harmless TayoCash and its officers, directors, employees, and agents from and against any and all claims or causes of action, including, but not limited to, personal injury, death, or damage to or loss of property, arising out of participation in the promo.
            </span>
            </li>
            <li><span>
              By joining the promo, the participants confirm that he or she has read, understood and agreed to these Promo Mechanics, and possess all the eligibility or qualifications to join the promo and none of the disqualifications.
            </span>
            </li>
            <li><span>
              For any concern, inquiry on the promo or the TayoCash subscriber’s account or eligibility, participants may contact ccg@tayocash.com or TayoCash Hotline (02) 8888-TAYO.
            </span>
            </li>
          </ol>
          <br />
        
          <p class="pa">
            Per DTI Fair Trade Permit No. FTEB-158008,<br />
            Series of 2022.<br />
            Supervised by the Bangko Sentral ng Pilipinas<br />
            Telephone number: (632)708-7087<br />
            E-mail Address: consumeraffairs@bsp.gov.ph
          </p>


          
        </div>

      
          

        <!--
        <div class="buttons">
          <a href="/privacypolicy" class="yellow">PRIVACY POLICY</a>
          <a href="/erm" class="white">ENTERPRISE RISK MANAGEMENT</a>
        </div>
        -->
      </div>
    </div>

    <div class="faq">
      <div class="ui grid container">
        <div class="ui horizontal accordion menu">
          <h3>Frequently Asked Questions</h3>

          <h4><b>WHAT IS TAYOCASH RAFFLE SPLASH</b></h4>

          <div @click="accordion" class="active title">
            <i class="dropdown icon"></i>
            What is TayoCash Raffle Splash promo?
          </div>
          <div class="active content">
            <p class="paw">
              It is the official weekly raffle promo of TayoCash running from December 12, 2022 to June 31, 2023 for a total of 28 electronic weekly draws.
            </p>
            <p class="paw">
              Promo participants get the chance to win prizes by simply earning raffle tickets whenever they perform TayoCash app transactions. Weekly raffle winners are drawn from the tickets earned during the applicable Promo Week (12:00 AM Monday to 11:59 PM Sunday).
            </p>
          </div>

          <div @click="accordion" class="title">
            <i class="dropdown icon"></i>
            Where do I find the full Promo Mechanics and Terms & Conditions of the promo?
          </div>
          <div class="content">
            <p class="paw">
              To review the full Promo Mechanics and Terms & Conditions of this promo, visit our website: (insert link)
            </p>
          </div>

          <h4><b>HOW TO JOIN</b></h4>

          <div @click="accordion" class="title">
            <i class="dropdown icon"></i>
            How do I join the promo?
          </div>
          <div class="content">
            <p class="paw">
              On your TayoCash app dashboard, register by clicking the ‘Join Now’ button inside the promo banner and submit all required information:</p>
              <h5>Full name</h5>
              <h5>Registered mobile number</h5>
              <h5>Email address</h5>
              <h5>Home address</h5>
            
            <p class="paw">You also need to confirm that you are at least 18 years of age and agree to the Promo Mechanics to register.</p>
          </div>

          <div @click="accordion" class="title">
            <i class="dropdown icon"></i>
            Do I need to register for each of the 28 weekly draws?
          </div>
          <div class="content">
            <p class="paw">
              No, you need only to register once to participate in all the raffle draws.
            </p>
          </div>

          <div @click="accordion" class="title">
            <i class="dropdown icon"></i>
            Who are eligible to join the promo?
          </div>
          <div class="content">
            <p class="paw">
              The raffle promo is open to all new and existing TayoCash app subscribers who are at least 18 years old at the time of registration to the promo.
            </p>
          </div>

          <div @click="accordion" class="title">
            <i class="dropdown icon"></i>
            Who are disqualified to join the promo?
          </div>
          <div class="content">
            <ul>
              <li>
                New and existing TayoCash app subscribers who are below 18 years old at the time of joining the promo
              </li>
              <li>
                Officers, employees, promo contractors and service providers of TayoCash, their agencies, subsidiaries, and their relatives up to the second degree of consanguinity and affinity;
              </li>
              <li>
                Former employees of TayoCash separated from employment within one (1) year from the start of the promo ;
              </li>
              <li>
                Inactive, suspended, deactivated or terminated TayoCash accounts, unless such accounts are reactivated during the promo period.
              </li>
            </ul>
          </div>

          <h4><b>HOW TO EARN TICKETS</b></h4>

          <div @click="accordion" class="title">
            <i class="dropdown icon"></i>
            How do I earn raffle tickets?
          </div>
          <div class="content">
            <p class="paw">
              You will earn tickets real-time when you do any of the following TayoCash transactions:
            </p>
            <div class="table-body2">
              <table class="ui celled structured table unstackable promo">
                <thead>
                  <tr>
                    <th>Covered TayoCash Activities</th>
                    <th>Tickets</th>
                    <th>Conditions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Existing and New Fully-Verified TayoCash Account Registration</td>
                    <td>1 Ticket / account</td>
                    <td>1. Upon registration to the promo, existing and new Fully-Verified TayoCash accounts shall receive 1 Ticket. <br /><br />2. This ticket can only be earned once.</td>
                  </tr>
                  <tr>
                    <td>
                      TayoCash Account Upgrade from Basic or Semi-Verified to Fully-Verified Account
                    </td>
                    <td>1 Ticket / account</td>
                    <td>1. The Ticket shall be applied to the applicable promo week when his or her application for a Fully-Verified account is approved. <br /><br />2. This ticket can only be earned once.</td>
                  </tr>
                  <tr>
                    <td>Account Upgrade from Basic or Semi-Verified to Fully-Verified Account</td>
                    <td>1 Ticket / account</td>
                    <td>Users shall only be eligible to earn this type of ticket once.</td>
                  </tr>
                  <tr>
                    <td>Buy Load</td>
                    <td>1 Ticket for every P100 of a single-receipt Buy Load transaction</td>
                    <td>Example: 3 Tickets for a single Buy Load receipt of P350.00.</td>
                  </tr>
                  <tr>
                    <td>Pay Bills</td>
                    <td>1 Ticket for every P100 of a single-receipt Pay Bills transaction</td>
                    <td>Example: 3 Tickets for a single Pay Bills receipt of P350.00.</td>
                  </tr>
                  <tr>
                    <td>PayQRPH</td>
                    <td>2 Tickets for every P100 of a single-receipt PayQRPH transaction</td>
                    <td>Example: 6 Tickets for a single PayQRPH receipt of P350.00.</td>
                  </tr>
                  <tr>
                    <td>Refer-a-Friend</td>
                    <td>2 Tickets to the Recruiter and 1 Ticket to the Recruit</td>
                    <td>1. Recruiter must be a Fully-Verified TayoCash account. <br /><br />2. Recruit must register for a Fully-Verified TayoCash account. <br /><br />3. Recruit must use the referral code of the Recruiter. <br /><br />4. Tickets shall be credited upon approval of the Fully-Verified TayoCash account of the Recruit. <br /><br />5. Recruiter is not allowed to refer themselves for another account that will also be their own. <br /><br />6. Recruit shall only be eligible to earn this type of Ticket once.</td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>

          <div @click="accordion" class="title">
            <i class="dropdown icon"></i>
            What is a PayQRPH transaction and how do I use this?
          </div>
          <div class="content">
            <p class="paw">
              A PayQRPH transaction is a type of transaction wherein you simply scan the merchant’s QRPH code to pay for your purchases.
            </p>
            <p class="paw">
              How to scan a merchant’s QRPH Code: 
            </p>
            <p class="pawa">Step 1: Open your TayoCash app and tap the ‘scan to pay’ icon found at the upper right corner of your dashboard. </p>
            <p class="pawa">Step 2: Scan the merchant’s QRPH code and once prompted by the application, type the amount to be paid. </p>
            <p class="pawa">Step 3: Check the details and approve the transaction by clicking the ‘confirm’ button. You will receive an SMS confirming a successful transaction.</p>
            
          </div>

          <div @click="accordion" class="title">
            <i class="dropdown icon"></i>
            What is a merchant’s QRPH Code and how do I know it is a QRPH code?
          </div>
          <div class="content">
            <p class="paw">
              A merchant’s QRPH code is the national QR code standard that allows it to accept QR payments of any customer using a mobile app of participating banks and e-wallets in the Philippines. For example, a TayoCash app user can scan the QRPH code of a merchant even if the QR code of the merchant is not a TayoCash QRPH code.
            </p>
            <p class="paw">
              A QR code is a QRPH code if it has the QRPH logo (the blue, red, and yellow design) at the center of the QR code.
            </p>
            <div class="mid-promoqr">
              <img src="@/assets/promoqr.png" alt="Tayocash" />
            </div>
          </div>

          <div @click="accordion" class="title">
            <i class="dropdown icon"></i>
            How do I earn tickets through Refer-a-Friend?
          </div>
          <div class="content">
            <p class="paw">
              Step 1: You must be a Fully-Verified TayoCash account. <br />
              Step 2: On the TayoCash app, tap on your profile and click the ‘Refer-a-friend’ button to view your referral code. You can copy and share the code to all your friends.<br /><br />
              
              Referral tickets will be awarded to both you and your friend each time you successfully refer a friend to register for a fully-verified account in TayoCash using your referral code. The tickets will be credited to you and your friend’s account upon full verification of your friend’s account.
            </p>
          </div>

          <div @click="accordion" class="title">
            <i class="dropdown icon"></i>
            How do I use the referral code?
          </div>
          <div class="content">
            <p class="paw">
              You can check your tickets anytime via the TayoCash app. Click ‘My Tickets’ under the promo page and tap ‘Tickets’.
            </p>
          </div>

          <div @click="accordion" class="title">
            <i class="dropdown icon"></i>
            Do my tickets expire?
          </div>
          <div class="content">
            <p class="paw">
              Yes. All raffle tickets earned within a given Promo Week (12:00 AM Monday to 11:59 PM Sunday) shall only be eligible and valid for the said week’s draw date. After each draw date, all winning and non-winning raffle tickets are automatically expired and excluded from any subsequent draw dates.
            </p>
          </div>

          <h4><b>WHEN AND HOW WEEKLY WINNERS ARE DRAWN</b></h4>

          <div @click="accordion" class="title">
            <i class="dropdown icon"></i>
            When are weekly winners drawn?
          </div>
          <div class="content">
            <p class="paw">
              Weekly winners are drawn every Thursday following the end of each Promo Week (12:00AM Monday to 11:59PM Sunday). Please see the schedule of the Promo Weeks and Draw Dates: (insert link)
            </p>
          </div>

          <div @click="accordion" class="title">
            <i class="dropdown icon"></i>
            How are winners drawn?
          </div>
          <div class="content">
            <p class="paw">
              Weekly winners are electronically drawn via a DTI-registered randomizer at the TayoCash office in the presence of DTI representatives.
            </p>
          </div>

          <h4><b>WHAT PRIZES TO WIN</b></h4>

          <div @click="accordion" class="title">
            <i class="dropdown icon"></i>
            What prizes can I win?
          </div>
          <div class="content">
            <p class="paw">
              Depending on the Promo Week, there will be five (5) to ten (10) winners who will each have a chance to win PhP5,000 or PhP10,000. Please see the schedule of Promo Weeks and Draw Dates: (insert link)
            </p>
          </div>

          <div @click="accordion" class="title">
            <i class="dropdown icon"></i>
            How many times can I win?
          </div>
          <div class="content">
            <p class="paw">
              A participant can only win once in a weekly draw. If the name of the participant is drawn more than once, another entry shall be drawn and qualified. But the winner can still win in the subsequent draws.
            </p>
          </div>

          <div @click="accordion" class="title">
            <i class="dropdown icon"></i>
            Will I get notified once I win?
          </div>
          <div class="content">
            <p class="paw">
              Yes. Winners will be notified via email and registered mail by TayoCash within 3 days from the time of draw. Announcements of winners shall also be made on any of TayoCash’s official channels: the official website (tayocash.com), Facebook (TayoCash PH) and from your TayoCash app.
            </p>
          </div>

          <h4><b>HOW TO REDEEM PRIZES</b></h4>


          <div @click="accordion" class="title">
            <i class="dropdown icon"></i>
            How do I redeem my prize?
          </div>
          <div class="content">
            <p class="paw">
              To redeem your prizes, you must comply with the following requirements within 60 calendar days from receipt of the registered mail above:    
            </p>
            <p class="paw">
              i.Submission of Tax Identification Number, and <br />Ii. Upgrade to a Fully-Verified TayoCash account, in case you are a Basic or Semi-Verified TayoCash account.
            </p>
            <p class="paw">
              Once you have complied with the redemption requirements within the 60-day period, TayoCash shall then credit the prize to your TayoCash account, net of 20% prize tax, within seven (7) business days from your full compliance with the redemption requirements above.
            </p>
            <p class="paw">
              In addition, crediting of prizes shall observe the applicable monthly aggregate wallet limit of the TayoCash account of the winners. If it shall breach the account wallet limit, the prize winnings will be credited as soon as the wallet limit has been refreshed.
            </p>
            <p class="paw">
              Failure to comply with the redemption requirements within the given period shall cause the forfeiture of the prize in favor of TayoCash with prior approval of DTI and notice to the participant.
            </p>
          </div>

          <div @click="accordion" class="title">
            <i class="dropdown icon"></i>
            Will I get notified once the prize has been credited to my TayoCash account?
          </div>
          <div class="content">
            <p class="paw">
              Yes. You will be notified via email by TayoCash of the wallet crediting, together with a Prize Acknowledgement Form (PAF). Within seven (7) business days from your receipt of the notice of wallet crediting, you must email back to TayoCash your accomplished PAF. Failure to acknowledge within the given period means the wallet crediting was successful.
            </p>
          </div>

          <div @click="accordion" class="title">
            <i class="dropdown icon"></i>
            What does wallet limit refresh mean?
          </div>
          <div class="content">
            <p class="paw">
              TayoCash accounts have a monthly wallet transaction limit. Your wallet limit refreshes based on when your account got fully-verified. The prize winnings shall be credited the following month or once the winner’s TayoCash account balance is once again within the allowable monthly wallet limit of P100,000 for fully-verified accounts.
            </p>
          </div>

          <div @click="accordion" class="title">
            <i class="dropdown icon"></i>
            Are prizes transferable?
          </div>
          <div class="content">
            <p class="paw">
              All winnings are non-transferable.
            </p>
          </div>

          <div @click="accordion" class="title">
            <i class="dropdown icon"></i>
            Can I opt for cash instead of wallet credits?
          </div>
          <div class="content">
            <p class="paw">
              All winnings are non-convertible to cash.
            </p>
          </div>

          <h4><b>OTHER QUESTIONS</b></h4>


          <div @click="accordion" class="title">
            <i class="dropdown icon"></i>
            I have other questions/concerns about the promo, what do I do?
          </div>
          <div class="content">
            <p class="paw">
              You may contact TayoCash at 8888-TAYO or email us at ccg@tayocash.com.
            </p>
          </div>


        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import $ from 'jquery'

export default {
  name: 'promo',
  data() {
    return {
      fromDrawDate: "",
      toDrawDate: ""
     };
  },
  methods: {
    accordion () {
      $('.ui.accordion').accordion()
    },
    formatDateDisplayWithoutTime(date) {
      if (date) {
        return moment(String(date)).format("MMM D, YYYY");
      }
    },
  },
  mounted () {
    $('.ui.accordion').accordion()
  },
  computed: {
    adminGetPromoSchedule() {
      return this.$store.getters.adminGetPromoSchedule;
    }
  },
  created() {
    return new Promise((resolve, reject) => {

      this.$store.dispatch("getPromoSchedule").then(
        (response) => {
            
            var promoSchedSize = this.adminGetPromoSchedule.length

            this.fromDrawDate = this.adminGetPromoSchedule[0].dateFrom
            this.toDrawDate = this.adminGetPromoSchedule[promoSchedSize - 1].drawDate
            resolve(response);

        }
      ).catch(error => {
        reject(error);
      })
    });
  }
}
</script>

<style scoped lang='scss'></style>
